<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Expenses</h1>
        <v-spacer></v-spacer>
        <CreateExpense />
        <v-btn to="/expense-report">Create a report</v-btn>
      </v-card-title>

      <v-card-text>
        <ExpenseList :expenses="expenses" :paginated=true title="All Expenses"></ExpenseList>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import ExpenseList from "@/components/expense/ExpenseList";
import CreateExpense from "@/components/expense/CreateExpense";

export default {
  components: {
    CreateExpense, ExpenseList
  },
  computed: {
    expenses() {
      return this.$store.getters.sortedExpenses;
    },
  },
  mounted() {
    this.$store.dispatch("loadExpenses");
  },
};
</script>